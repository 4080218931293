import React, {Component} from "react";
import {getMatrix, getZones} from "./Data";
import {dev, devIp, ip} from "../package.json"

class Bruynzeel extends Component {

    state = {
        zipCode: "",
        kg: "",
        ldm: "",
        price: 0,
        lineHaul: 0,
        oilSurcharge: undefined,
        loadmeterConversionNo: undefined,
        distributionRateIncreaseNo: undefined,
        lineHaulIncreaseNo: undefined,
        zones: [],
        zone: {},
        ip: dev ? devIp : ip
    };

    componentDidMount() {
        let zones = [];
        getZones().split("\n").forEach(s => {
            let l = s.split(",");
            let zoneObj = {};
            zoneObj.postNo = l[0];
            zoneObj.zone = l[1];
            zoneObj.transit = l[2];
            zones.push(zoneObj)
        });
        this.setState({zones});
        fetch(ip + "settings")
            .then(res => res.json().then(result => {

                this.setState({oilSurcharge: result.oilSurchargeNO,
                                    loadmeterConversionNo: result.loadmeterConversionNo,
                                    distributionRateIncreaseNo: result.distributionRateIncreaseNo,
                                    lineHaulIncreaseNo: result.lineHaulIncreaseNo,
                }, this.calcPrice)
            }));
    }


    calcHighValue = () => {
        return this.state.ldm * this.state.loadmeterConversionNo > this.state.kg ? this.state.ldm * this.state.loadmeterConversionNo : this.state.kg;
    };

    calcZone = () => {
        let zones = [...this.state.zones];
        let selectedZone = undefined;
        for (let zone of zones) {
            if (zone.postNo <= this.state.zipCode) {
                selectedZone = zone;
            }
        }

        if (selectedZone !== undefined) {
            this.setState({zone: selectedZone});
        }
        return selectedZone;
    };

    calcInterval = weight => {
        let interval = [1, 26, 51, 76, 101, 151, 201, 251, 301, 351, 401, 451, 501, 601, 701, 801, 1001, 2501, 5001, 7501, 10001, 15001, 20000]
        let j = 0;
        for (let i = 0; i < interval.length; i++) {
            if (interval[i] <= weight) {
                j = i;
            }
        }
        return j;
    };

    calcLineHaul = weight => {
        let price;
        let interval = [0, 2.1, 4.1, 7.1, 10.10];
        let prices = [1827, 1786, 1786, 1751, 1751];
        let ldm = weight / this.state.loadmeterConversionNo;

        let j = 0;
        for (let i = 0; i < interval.length; i++) {
            if (interval[i] <= ldm) {
                j = i;
            }
        }
        const minimum = 1953 * (1 + this.state.lineHaulIncreaseNo / 100);
        price = prices[j] * (1 + this.state.lineHaulIncreaseNo / 100);
        price = price * ldm < minimum ? minimum : price * ldm;
        price *= (1 + this.state.oilSurcharge / 100);
        return price;
    };

    calcPrice = () => {
        let price;
        let weight = this.calcHighValue();
        let zone = this.calcZone();
        let weightInterval = this.calcInterval(weight);
        if (zone !== undefined && weightInterval !== undefined) {
            price = getMatrix()[zone.zone][weightInterval];
            price *= (1 + this.state.distributionRateIncreaseNo / 100);
        }
        if (weight > 1000) {
            price *= Math.ceil(weight / 100);
        }
        this.setState({price, lineHaul: this.calcLineHaul(weight)});
    };

    handleChange = e => {
        e.target.value = e.target.value.replace(" ", "");
        if (e.target.name === "ldm" && e.target.value > 13.4) {
            // IGNORED
        } else if (e.target.name === "kg" && e.target.value > 24790) {
            // IGNORED
        } else if (e.target.name === "zipCode" && e.target.value > 9999) {
            // IGNORED
        } else {
            this.setState({[e.target.name]: e.target.value.replace(",", ".")}, this.calcPrice)
        }
    };

    render() {
        return (
            <div style={{padding: 10, margin: 10}}>
                <div className={"columns"}>
                    <div className={"card column is-4"}>
                        <InputP type="text" text={"Postnummer:"} value={this.state.zipCode} onChange={this.handleChange}
                                name={"zipCode"}/>
                        <InputP type="number" text={"LDM:"} value={this.state.ldm} onChange={this.handleChange}
                                name={"ldm"}/>
                        <InputP type="number" text={"Kg:"} value={this.state.kg} onChange={this.handleChange}
                                name={"kg"}/>
                        <h1>{"Transit time: " + this.state.zone.transit + " day(s)"}</h1>
                        <h1>{"Oil surcharge: " + this.state.oilSurcharge + "%"}</h1>
                        <h1>{"Line haul Price: " + Math.round(this.state.lineHaul * 100) / 100 + " NOK"}</h1>
                        <h1>{"Price: " + Math.round(this.state.price * 100) / 100 + " NOK"}</h1>
                        <h1 style={{fontWeight: 700}}>{"Total: " + Math.round(this.state.price * 100 + this.state.lineHaul * 100) / 100 + " NOK*"}</h1>
                    </div>
                </div>
            </div>
        );
    }
}

export default Bruynzeel;


const InputP = props => {
    return (
        <div className="field">
            <label className="label">{props.text}</label>
            <div className="control">
                <input className={props.type === "checkbox" ? null : "input"} type={props.type} value={props.value}
                       onChange={props.onChange} name={props.name}/>
            </div>
        </div>
    )
};