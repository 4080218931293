export function getMatrix() {
    return {
         1: [148.2,  177.0,  181.3,  210.1,  261.9,  315.1,  366.9,  401.4,  420.1,  431.6,  441.7,  451.8,  492.1,  561.1,  601.4,  702.1,  54.7,  50.4,  48.9,  44.6,  43.2,  40.3,  33.1],
         2: [168.3,  198.6,  208.6,  238.8,  300.7,  362.6,  424.4,  487.7,  533.8,  610.0,  669.0,  733.8,  860.4,  989.9, 1117.9, 1244.5,  73.4,  64.7,  59.0,  53.2,  50.4,  46.0,  43.2],
         3: [194.2,  237.4,  287.8,  338.1,  430.2,  520.8,  615.8,  710.8,  797.1,  883.4,  968.3, 1053.2, 1246.0, 1440.2, 1633.0, 1825.8,  84.9,  66.2,  64.7,  64.7,  60.4,  56.1,  53.2],
         4: [215.8,  253.2,  303.6,  352.5,  450.3,  546.7,  643.1,  741.0,  837.4,  933.8, 1030.2, 1123.7, 1339.5, 1555.3, 1769.7, 1987.0,  93.5,  70.5,  66.2,  64.7,  63.3,  59.0,  54.7],
         5: [247.5,  309.3,  371.2,  436.0,  558.2,  680.5,  802.8,  926.6, 1033.0, 1136.6, 1240.2, 1345.3, 1574.0, 1805.7, 2037.3, 2267.5, 118.0, 103.6, 100.7,  97.8,  93.5,  84.9,  80.6],
         6: [246.8,  300.5,  353.0,  406.7,  523.1,  636.9,  753.3,  865.8,  965.6, 1064.1, 1161.3, 1257.2, 1473.3, 1689.4, 1905.6, 2123.0, 110.0,  97.2,  93.4,  92.1,  88.2,  83.1,  79.3],
         7: [272.4,  361.9,  451.5,  543.5,  682.9,  821.1,  961.7, 1102.4, 1275.1, 1442.6, 1614.0, 1784.1, 1931.2, 2080.8, 2227.9, 2372.4, 124.1, 102.3,  98.5,  97.2,  93.4,  89.5,  87.0],
         8: [300.5,  391.3,  482.2,  573.0,  718.8,  864.5, 1011.6, 1156.1, 1333.9, 1514.2, 1694.6, 1873.6, 2030.9, 2186.9, 2343.0, 2497.7, 131.7, 110.0, 106.2, 102.3,  98.5,  95.9,  88.2],
         9: [351.7,  471.9,  589.6,  709.8,  883.7, 1056.4, 1230.3, 1401.7, 1657.5, 1841.6, 2061.6, 2282.9, 2477.3, 2669.1, 2859.7, 3050.2, 214.9, 197.0, 184.2, 168.8, 149.6, 140.7, 111.3],
        10: [453.2,  581.3,  706.4,  837.4, 1040.2, 1243.1, 1446.0, 1650.3, 1912.1, 2175.4, 2438.7, 2699.2, 2922.2, 3145.2, 3366.7, 3592.6, 287.8, 271.9, 254.7, 234.5, 218.7, 200.0, 172.7],
        11: [510.8,  674.8,  791.3, 1005.7, 1282.0, 1555.3, 1828.7, 2107.8, 2441.6, 2772.5, 3106.3, 3443.0, 3647.3, 3968.2, 4230.0, 4496.2, 293.5, 287.8, 273.4, 259.0, 244.6, 223.0, 202.9],
        12: [569.8,  725.1,  870.5, 1040.2, 1312.2, 1585.5, 1858.9, 2130.8, 2467.5, 2805.6, 3143.7, 3481.8, 3704.9, 4027.1, 4302.0, 4575.3, 297.8, 293.5, 277.7, 263.3, 248.9, 227.3, 207.2],
        13: [628.7,  771.2,  916.5, 1061.8, 1335.2, 1607.1, 1879.0, 2149.5, 2492.0, 2831.5, 3172.5, 3512.1, 3794.1, 4073.2, 4355.2, 4635.7, 297.8, 293.5, 277.7, 263.3, 248.9, 227.3, 207.2],
        14: [679.1,  854.6, 1023.0, 1194.2, 1492.0, 1788.4, 2086.2, 2385.5, 2746.6, 3109.2, 3470.3, 3832.9, 4107.7, 4384.0, 4660.2, 4936.5, 336.7, 323.7, 316.5, 309.3, 296.4, 273.4, 253.2],
        15: [745.3,  933.8, 1120.8, 1312.2, 1640.2, 1966.8, 2294.9, 2618.6, 3018.6, 3417.1, 3817.1, 4214.2, 4525.0, 4835.7, 5148.0, 5458.7, 368.3, 353.9, 348.2, 338.1, 326.6, 303.6, 282.0],
        16: [804.3, 1012.9, 1221.5, 1434.5, 1791.3, 2148.1, 2504.9, 2866.1, 3302.0, 3736.5, 4172.5, 4607.0, 4935.0, 5264.5, 5592.5, 5920.6, 431.6, 412.9, 391.3, 371.2, 351.1, 328.0, 307.9],
        17: [853.2, 1056.1, 1257.5, 1460.4, 1835.9, 2207.1, 2578.3, 2950.9, 3399.8, 3850.2, 4299.1, 4746.5, 5110.5, 5473.1, 5835.7, 6202.6, 441.7, 420.1, 398.5, 377.0, 356.8, 333.8, 328.0],
    }


}


export function getZones() {
    return ("0,1,1\n" +
        "1300,1,1\n" +
        "1309,2,1\n" +
        "1311,1,1\n" +
        "1317,2,1\n" +
        "1318,1,1\n" +
        "1333,2,1\n" +
        "1336,1,1\n" +
        "1348,2,1\n" +
        "1356,1,1\n" +
        "1362,2,1\n" +
        "1363,1,1\n" +
        "1370,2,1\n" +
        "1397,1,1\n" +
        "1400,2,1\n" +
        "1540,3,1\n" +
        "1591,2,1\n" +
        "1620,3,1\n" +
        "1640,4,1\n" +
        "1670,6,1\n" +
        "1701,2,1\n" +
        "1712,3,1\n" +
        "1714,4,1\n" +
        "1721,2,1\n" +
        "1730,4,1\n" +
        "1751,3,1\n" +
        "1790,6,1\n" +
        "1800,3,1\n" +
        "1806,6,1\n" +
        "1807,3,1\n" +
        "1816,6,1\n" +
        "1820,3,1\n" +
        "1859,6,1\n" +
        "1860,4,1\n" +
        "1870,6,1\n" +
        "1900,3,1\n" +
        "2110,5,1\n" +
        "2114,4,1\n" +
        "2116,5,1\n" +
        "2150,3,1\n" +
        "2164,4,1\n" +
        "2201,3,1\n" +
        "2210,5,1\n" +
        "2211,3,1\n" +
        "2216,5,1\n" +
        "2225,3,1\n" +
        "2230,5,1\n" +
        "2265,6,1\n" +
        "2301,3,1\n" +
        "2312,4,1\n" +
        "2315,3,1\n" +
        "2320,4,1\n" +
        "2321,3,1\n" +
        "2323,4,1\n" +
        "2325,3,1\n" +
        "2330,4,1\n" +
        "2364,6,1\n" +
        "2380,4,1\n" +
        "2410,5,1\n" +
        "2411,4,1\n" +
        "2412,5,1\n" +
        "2415,6,1\n" +
        "2418,4,1\n" +
        "2420,6,2\n" +
        "2428,5,2\n" +
        "2430,6,2\n" +
        "2440,7,2\n" +
        "2446,8,2\n" +
        "2450,6,1\n" +
        "2460,7,1\n" +
        "2512,8,1\n" +
        "2601,4,1\n" +
        "2610,7,1\n" +
        "2611,4,1\n" +
        "2612,7,1\n" +
        "2613,4,1\n" +
        "2616,7,1\n" +
        "2618,4,1\n" +
        "2625,6,1\n" +
        "2649,7,1\n" +
        "2659,8,1\n" +
        "2670,6,1\n" +
        "2672,8,1\n" +
        "2675,6,1\n" +
        "2680,8,1\n" +
        "2711,3,1\n" +
        "2806,4,1\n" +
        "2815,3,1\n" +
        "2827,4,1\n" +
        "2838,6,1\n" +
        "2840,4,1\n" +
        "2870,5,1\n" +
        "2879,4,1\n" +
        "2880,8,1\n" +
        "2882,5,1\n" +
        "2890,8,1\n" +
        "2900,5,1\n" +
        "2917,8,1\n" +
        "2920,5,1\n" +
        "2923,8,1\n" +
        "3001,2,1\n" +
        "3050,3,1\n" +
        "3060,4,1\n" +
        "3080,3,1\n" +
        "3088,4,1\n" +
        "3101,3,1\n" +
        "3107,4,1\n" +
        "3109,3,1\n" +
        "3132,4,1\n" +
        "3143,5,1\n" +
        "3150,4,1\n" +
        "3165,5,1\n" +
        "3166,4,1\n" +
        "3181,3,1\n" +
        "3243,4,1\n" +
        "3244,3,1\n" +
        "3275,4,1\n" +
        "3295,3,1\n" +
        "3320,4,1\n" +
        "3322,5,1\n" +
        "3400,3,1\n" +
        "3420,4,1\n" +
        "3474,5,1\n" +
        "3500,3,1\n" +
        "3520,4,1\n" +
        "3522,5,1\n" +
        "3529,4,1\n" +
        "3534,5,1\n" +
        "3538,4,1\n" +
        "3539,6,1\n" +
        "3550,5,1\n" +
        "3560,6,1\n" +
        "3570,5,1\n" +
        "3576,6,1\n" +
        "3590,7,1\n" +
        "3600,3,1\n" +
        "3618,5,1\n" +
        "3619,3,1\n" +
        "3620,5,1\n" +
        "3626,6,1\n" +
        "3646,5,1\n" +
        "3650,6,1\n" +
        "3670,4,1\n" +
        "3690,6,1\n" +
        "3701,4,1\n" +
        "3748,5,1\n" +
        "3750,6,1\n" +
        "3770,4,1\n" +
        "3780,6,1\n" +
        "3791,4,1\n" +
        "3793,6,1\n" +
        "3855,8,2\n" +
        "3864,6,2\n" +
        "3883,8,2\n" +
        "3884,6,2\n" +
        "3901,4,1\n" +
        "3947,5,1\n" +
        "3948,4,1\n" +
        "3950,5,1\n" +
        "3961,4,1\n" +
        "3965,5,1\n" +
        "4001,6,1\n" +
        "4076,8,1\n" +
        "4088,6,1\n" +
        "4100,9,2\n" +
        "4250,8,1\n" +
        "4300,6,1\n" +
        "4310,8,1\n" +
        "4312,6,1\n" +
        "4330,8,1\n" +
        "4340,6,1\n" +
        "4342,8,1\n" +
        "4349,6,1\n" +
        "4352,7,1\n" +
        "4355,8,1\n" +
        "4358,7,1\n" +
        "4360,8,1\n" +
        "4370,6,1\n" +
        "4375,8,1\n" +
        "4379,6,1\n" +
        "4380,8,1\n" +
        "4391,6,1\n" +
        "4395,8,1\n" +
        "4400,6,1\n" +
        "4420,8,1\n" +
        "4501,6,1\n" +
        "4507,7,1\n" +
        "4513,6,1\n" +
        "4519,7,1\n" +
        "4528,8,1\n" +
        "4529,7,1\n" +
        "4536,8,1\n" +
        "4604,5,1\n" +
        "4619,7,1\n" +
        "4621,5,1\n" +
        "4640,7,1\n" +
        "4659,5,1\n" +
        "4671,7,1\n" +
        "4673,5,1\n" +
        "4679,7,2\n" +
        "4687,5,1\n" +
        "4698,7,1\n" +
        "4733,9,1\n" +
        "4766,7,1\n" +
        "4801,5,1\n" +
        "4810,7,1\n" +
        "4825,5,1\n" +
        "4827,7,1\n" +
        "4830,8,1\n" +
        "4836,5,1\n" +
        "4851,7,1\n" +
        "4856,5,1\n" +
        "4863,8,1\n" +
        "4870,7,1\n" +
        "4876,6,1\n" +
        "4889,7,1\n" +
        "4890,6,1\n" +
        "4900,7,1\n" +
        "4950,6,1\n" +
        "4971,7,1\n" +
        "5003,6,1\n" +
        "5048,7,1\n" +
        "5052,6,1\n" +
        "5060,7,1\n" +
        "5063,6,1\n" +
        "5101,7,1\n" +
        "5108,8,1\n" +
        "5111,7,1\n" +
        "5134,8,1\n" +
        "5141,7,1\n" +
        "5150,9,2\n" +
        "5151,7,1\n" +
        "5200,8,1\n" +
        "5221,7,1\n" +
        "5229,8,1\n" +
        "5231,7,1\n" +
        "5260,8,1\n" +
        "5281,9,2\n" +
        "5341,8,2\n" +
        "5346,9,2\n" +
        "5350,8,2\n" +
        "5357,9,2\n" +
        "5371,10,2\n" +
        "5400,9,2\n" +
        "5444,10,2\n" +
        "5445,9,2\n" +
        "5450,10,2\n" +
        "5500,7,1\n" +
        "5508,8,1\n" +
        "5513,7,1\n" +
        "5520,8,1\n" +
        "5521,7,1\n" +
        "5541,8,2\n" +
        "5545,7,2\n" +
        "5546,8,2\n" +
        "5547,10,3\n" +
        "5550,8,2\n" +
        "5561,10,2\n" +
        "5563,8,2\n" +
        "5600,9,2\n" +
        "5626,10,3\n" +
        "5630,9,2\n" +
        "5700,7,2\n" +
        "5707,8,2\n" +
        "5710,7,2\n" +
        "5711,8,2\n" +
        "5716,11,3\n" +
        "5717,8,2\n" +
        "5718,10,3\n" +
        "5719,7,3\n" +
        "5721,8,2\n" +
        "5729,9,2\n" +
        "5730,8,2\n" +
        "5741,10,2\n" +
        "5747,8,2\n" +
        "5748,10,2\n" +
        "5750,7,2\n" +
        "5760,9,2\n" +
        "5778,10,2\n" +
        "5780,9,2\n" +
        "5783,10,2\n" +
        "5787,9,2\n" +
        "5803,6,1\n" +
        "5898,11,3\n" +
        "5902,9,2\n" +
        "5969,6,1\n" +
        "5970,9,2\n" +
        "6001,7,1\n" +
        "6040,9,2\n" +
        "6087,10,2\n" +
        "6089,9,2\n" +
        "6100,8,2\n" +
        "6110,9,2\n" +
        "6150,8,2\n" +
        "6165,9,2\n" +
        "6170,8,2\n" +
        "6183,9,2\n" +
        "6200,8,2\n" +
        "6210,10,2\n" +
        "6212,8,2\n" +
        "6213,10,2\n" +
        "6218,8,2\n" +
        "6290,9,2\n" +
        "6300,7,1\n" +
        "6310,8,2\n" +
        "6400,7,1\n" +
        "6408,9,2\n" +
        "6409,7,1\n" +
        "6418,9,2\n" +
        "6419,7,1\n" +
        "6430,8,2\n" +
        "6457,9,2\n" +
        "6460,8,2\n" +
        "6475,9,2\n" +
        "6490,8,2\n" +
        "6500,7,1\n" +
        "6520,9,2\n" +
        "6570,10,2\n" +
        "6600,8,2\n" +
        "6610,9,2\n" +
        "6689,11,2\n" +
        "6693,10,2\n" +
        "6697,11,2\n" +
        "6700,9,2\n" +
        "6704,10,2\n" +
        "6708,9,2\n" +
        "6710,10,2\n" +
        "6711,9,2\n" +
        "6714,10,2\n" +
        "6721,9,2\n" +
        "6726,11,2\n" +
        "6730,10,2\n" +
        "6761,9,2\n" +
        "6770,8,2\n" +
        "6776,9,2\n" +
        "6781,8,1\n" +
        "6784,9,2\n" +
        "6798,8,2\n" +
        "6800,7,1\n" +
        "6806,9,2\n" +
        "6829,10,2\n" +
        "6841,9,2\n" +
        "6851,7,1\n" +
        "6854,9,1\n" +
        "6855,10,2\n" +
        "6856,7,1\n" +
        "6858,9,2\n" +
        "6881,10,2\n" +
        "6886,9,2\n" +
        "6888,11,2\n" +
        "6891,9,2\n" +
        "6900,8,1\n" +
        "6912,11,2\n" +
        "6921,9,2\n" +
        "7003,6,2\n" +
        "7100,9,2\n" +
        "7166,11,2\n" +
        "7200,8,2\n" +
        "7211,7,2\n" +
        "7228,8,2\n" +
        "7239,10,3\n" +
        "7255,9,3\n" +
        "7260,10,3\n" +
        "7280,11,3\n" +
        "7288,8,2\n" +
        "7315,9,2\n" +
        "7320,8,2\n" +
        "7361,6,1\n" +
        "7370,8,2\n" +
        "7374,6,1\n" +
        "7380,8,2\n" +
        "7400,6,1\n" +
        "7500,8,1\n" +
        "7540,6,1\n" +
        "7550,8,1\n" +
        "7619,9,1\n" +
        "7650,8,1\n" +
        "7670,9,2\n" +
        "7700,8,1\n" +
        "7710,9,1\n" +
        "7711,8,1\n" +
        "7730,9,1\n" +
        "7732,8,1\n" +
        "7739,9,2\n" +
        "7740,10,2\n" +
        "7750,9,2\n" +
        "7770,10,2\n" +
        "7790,9,2\n" +
        "7800,8,2\n" +
        "7817,9,2\n" +
        "7818,11,3\n" +
        "7819,9,2\n" +
        "7882,10,3\n" +
        "7940,11,3\n" +
        "7980,12,3\n" +
        "7985,11,3\n" +
        "8001,10,2\n" +
        "8050,12,3\n" +
        "8063,14,4\n" +
        "8064,17,4\n" +
        "8089,10,2\n" +
        "8093,12,4\n" +
        "8099,egen,egen\n" +
        "8100,13,4\n" +
        "8200,10,2\n" +
        "8220,11,3\n" +
        "8233,10,3\n" +
        "8250,11,3\n" +
        "8260,12,3\n" +
        "8270,13,4\n" +
        "8300,14,4\n" +
        "8352,15,4\n" +
        "8378,14,4\n" +
        "8380,15,4\n" +
        "8400,13,4\n" +
        "8408,14,3\n" +
        "8500,12,2\n" +
        "8522,13,3\n" +
        "8601,10,2\n" +
        "8603,11,2\n" +
        "8605,10,2\n" +
        "8609,11,2\n" +
        "8622,10,2\n" +
        "8629,11,3\n" +
        "8650,10,2\n" +
        "8672,11,3\n" +
        "8700,12,4\n" +
        "8725,11,4\n" +
        "8730,12,4\n" +
        "8800,11,3\n" +
        "8813,12,2\n" +
        "9040,14,3\n" +
        "9060,15,3\n" +
        "9103,16,3\n" +
        "9143,14,3\n" +
        "9144,15,3\n" +
        "9170,egen,egen\n" +
        "9180,16,4\n" +
        "9251,12,2\n" +
        "9300,13,3\n" +
        "9302,14,3\n" +
        "9303,15,3\n" +
        "9305,13,3\n" +
        "9310,14,3\n" +
        "9311,15,3\n" +
        "9315,14,3\n" +
        "9316,15,3\n" +
        "9321,14,3\n" +
        "9350,15,3\n" +
        "9360,14,3\n" +
        "9372,15,3\n" +
        "9400,12,2\n" +
        "9415,13,3\n" +
        "9420,14,3\n" +
        "9430,13,3\n" +
        "9439,14,3\n" +
        "9475,13,3\n" +
        "9477,12,2\n" +
        "9500,14,3\n" +
        "9519,15,5\n" +
        "9531,17,5\n" +
        "9533,15,5\n" +
        "9545,16,5\n" +
        "9550,15,5\n" +
        "9580,16,5\n" +
        "9600,15,3\n" +
        "9609,16,5\n" +
        "9610,15,5\n" +
        "9624,17,5\n" +
        "9626,15,5\n" +
        "9650,16,5\n" +
        "9663,17,5\n" +
        "9700,15,4\n" +
        "9709,16,5\n" +
        "9711,15,4\n" +
        "9713,16,5\n" +
        "9714,17,5\n" +
        "9715,16,4\n" +
        "9740,17,5\n" +
        "9800,15,4\n" +
        "9826,16,4\n" +
        "9900,15,4\n" +
        "9910,16,5\n" +
        "9915,15,4\n" +
        "9916,16,4\n" +
        "9917,15,4\n" +
        "9925,16,5\n" +
        "9950,15,4\n" +
        "9960,16,5\n" +
        "9980,17,5");
}

