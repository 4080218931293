import React, {Component} from 'react';
import './App.css';
import Bruynzeel from "./Bruynzeel";
import Login from "./Login";

class App extends Component {

    state = {
        loggedIn: false
    };

    setLoggedIn = () => {
        this.setState({loggedIn: !this.state.loggedIn})
    };

    render() {
        if (this.state.loggedIn) {
            return (
                <Bruynzeel/>
            )
        } else {
            return (
                <Login setLoggedIn={this.setLoggedIn}/>
            )
        }
    }
}

export default App;