import React, {Component} from 'react';
import {dev, devIp, ip} from "../package.json"

class Login extends Component {


    constructor(props) {
        super(props);
        this.state = {
            username: "",
            password: "",
            error: false,
            errorMessage: "",
            ip: dev ? devIp : ip,
            redirect: false,
        }
    }

    componentDidMount() {
        let user = localStorage.getItem("user");
        if (user !== null) {
            this.setState({redirect: true})
        }
    }

    handleChange = (e) => {
        this.setState({[e.target.name]: e.target.value});
    };

    login = () => {
        let user = {};
        user.email = this.state.username;
        user.password = this.state.password;
        fetch(this.state.ip + "login", {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(user)
        })
            .then(resp => resp.json().then(user => {
                if (user.success) {
                    localStorage.setItem("user", JSON.stringify(user.user));
                    this.setState({redirect: true})
                    this.props.setLoggedIn();
                } else {
                    this.setState({error: true, errorMessage: user.errorMessage})
                }
            }))
    };

    handleKeyPress = e => {
        if (e.key === 'Enter') {
            this.login()
        }
    };

    render() {
        if (this.state.redirect) {
            this.props.setLoggedIn()
        }
        return (
            <div>
                <div className={"column is-4"}>
                    <InputP
                        text={"Email"}
                        type="email"
                        className="input"
                        placeholder={"Email"}
                        value={this.state.username}
                        name="username"
                        onKeyPress={this.handleKeyPress}
                        onChange={this.handleChange}/>
                    <InputP
                        text={"Password"}
                        type="password"
                        className="input"
                        placeholder={"Password"}
                        value={this.state.password}
                        name="password"
                        onKeyPress={this.handleKeyPress}
                        onChange={this.handleChange}/>
                    <input className="button" style={{background: "#000085", color: "white"}} type="submit" value={"Login"} onClick={() => this.login()}/>
                    {this.state.errorMessage !== "" ?
                        <div>
                            <p style={{color: "black"}}> {this.state.errorMessage}</p>
                        </div>
                        : null}
                </div>
            </div>
        );
    }
}

const InputP = props => {
    return (
        <div className="field">
            <label className="label">{props.text}</label>
            <div className="control">
                <input className={props.type === "checkbox" ? null : "input"} placeholder={props.placeholder} type={props.type} value={props.value} onChange={props.onChange} name={props.name}/>
            </div>
        </div>
    )
};

export default Login;
